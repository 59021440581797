<template>
  <TheLayout title="Setup Complete" :message="message" :status="status" :isLoading="isLoading" @downloadReceipt="downloadReceipt" />
</template>

<script>
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Payment Complete',
  components: {
    TheLayout: defineAsyncComponent(() => import('../views/TheLayout.vue'))
  },
  data () {
    return {
      message: '',
      status: null,
      isLoading: false,
      theDonationId: null
    }
  },
  methods: {
    ...mapActions(['downloadRecurringDonationSummary', 'verifyStripeSubscription', 'fetchUserTransactions']),
    async downloadReceipt () {
      this.isLoading = true
      await this.downloadRecurringDonationSummary(this.theDonationId)
      setTimeout(() => {
        this.isLoading = false
      }, 3000)
    },
    async checkTransactionStatus (subscriptionRef) {
      if (subscriptionRef) {
        const result = await this.verifyStripeSubscription(subscriptionRef)
        if (result) {
          this.status = result.data.result.status
          if (result.data.result.status === 'Success') {
            this.message = 'Your recurring donation has been setup successfully'
            this.downloadReceipt()
            if (this.user) {
              this.fetchUserTransactions()
            }
          } else {
            this.message = result.data.result.description
          }
        } else {
          this.message = 'There was an issue with your payment, please try again later or contact support'
        }
      }
    }
  },
  async mounted () {
    const subscriptionRef = this.$route.params.subscriptionRef
    this.theDonationId = this.$route.params.donationId
    if (subscriptionRef) {
      await this.checkTransactionStatus(subscriptionRef)
    }
  }
}
</script>
