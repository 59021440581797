<template>
  <div class="container-lg" >
    <div class="row mx-0 mb-5 pb-5">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                View, manage and complete your pledges
              </div>
            </div>
            <!-- <div class="row m-0 justify-content-center">
              <div class="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 my-4">
                <Card icon="icon" heading="Setup Daily Sadaqah" @click="goTo('/dailysadaqah')" class="cursor">
                  <IconRecurring color="gold" size="size28" />
                </Card>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5 mt-5 pt-5">
      <div class="col-12" >
        <PledgesTable />
      </div>
    </div>
    <!-- <RecurringPaymentPopup v-if="isRecurring" @close="isRecurring = false" /> -->
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('../components/PageTitle.vue')),
    PledgesTable: defineAsyncComponent(() => import('../views/PledgesTable.vue'))
  },
  name: 'Pledges',
  data () {
    return {
      isRecurring: false
    }
  },
  methods: {
    goTo (val) {
      this.$router.push(val)
    }
  }
}
</script>

<style scoped>
</style>
