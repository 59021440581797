<template>
  <div class="row m-0">
    <div class="col-12">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center">
          <div class="col-11 text-center mt-4 page_heading d-none d-md-block">
            View your donation cart
          </div>
          <div class="col-12 col-sm-11 col-md-10 col-lg-10 col-xl-8 col-xxl-7 p-0 mt-4">
            <CartCard v-model:paymentType="paymentType" :editStatus="isEdit" @saveEdit="saveEdit" @login="isLogin = true" />
            <div class="row mt-4">
              <DonatingAs ref="thedonor" v-if="user" />
              <AnonymousDonor
                v-else
                v-model:firstName="firstName"
                v-model:lastName="lastName"
                v-model:email="email"
                v-model:mobile="mobile"
                v-model:addressLine1="primaryAddress.addressLine1"
                v-model:town="primaryAddress.town"
                v-model:postalCode="primaryAddress.postalCode"
                v-model:country="primaryAddress.country"
                :firstNameErrors="v$.firstName.$errors"
                :lastNameErrors="v$.lastName.$errors"
                :emailErrors="v$.email.$errors"
                :mobileErrors="v$.mobile.$errors"
                :addressLine1Errors="v$.primaryAddress.addressLine1.$errors"
                :townErrors="v$.primaryAddress.town.$errors"
                @showLogin="isLogin = true"
              />
              <GiftAidSelect
                v-model:ownMoney="ownMoney"
                v-model:giftAid="giftAid"
                :total="totalNumber"
                :currency="cart.length > 0 ? cart[0].currency : ''"
              />
            </div>
            <PaymentUK @verifyAnonDetails="verifyAnonDetails" @getCurrentTransactionReference="getCurrentTransactionReference(1)" :anonymousDetailsInvalid="anonymousDetailsInvalid" :paymentType="paymentType" :disabled="cart.length < 1" :transactionResponse="transactionResponse" v-model:isLoading="isLoading" />
          </div>
        </div>
      </div>
    </div>
    <GiftAidSection />
    <transition name="fade">
      <LoginPopup v-if="isLogin" @close="isLogin = false" @loggedIn="isLogin = false"/>
    </transition>
  </div>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    CartCard: defineAsyncComponent(() => import('./views/cart/CartCard.vue')),
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue')),
    DonatingAs: defineAsyncComponent(() => import('./views/donor/DonatingAs.vue')),
    AnonymousDonor: defineAsyncComponent(() => import('./views/donor/AnonymousDonor.vue')),
    GiftAidSelect: defineAsyncComponent(() => import('./views/giftaid/GiftAidSelect.vue')),
    GiftAidSection: defineAsyncComponent(() => import('./views/giftaid/GiftAidSection.vue')),
    PaymentUK: defineAsyncComponent(() => import('./views/payment/PaymentUK.vue'))
  },
  name: 'Donation Checkout',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      mobile: {
        required
      },
      primaryAddress: {
        addressLine1: {
          required
        },
        town: {
          required
        }
      }
    }
  },
  data () {
    return {
      isEdit: false,
      isLogin: false,
      ownMoney: false,
      giftAid: false,
      donateOnBehalf: false,
      paymentType: 'onceOff',
      donatingID: null,
      transactionResponse: null,
      isLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      primaryAddress: {
        addressLine1: '',
        town: '',
        postalCode: '',
        country: {
          countryID: null,
          description: '',
          isoCountryCode2: ''
        }
      },
      theCheckoutDetails: null,
      ukPaymentDetails: null
    }
  },
  mounted () {
    const edit = parseInt(this.$route.params.id)
    if (edit === 2) {
      this.isEdit = false
      this.goToDonor()
      this.donateOnBehalf = true
    } else if (edit === 1) {
      this.isEdit = true
    } else {
      this.isEdit = false
    }
    if (this.donateAs) {
      this.donatingID = this.donateAs.userID
    } else if (!this.donateAs && this.user) {
      this.donatingID = this.user.userID
    }
  },
  computed: {
    ...mapGetters([
      'cart', 'user', 'donateAs'
    ]),
    anonymousDetailsInvalid () {
      console.log('anonymousDetailsInvalid')
      if (!this.user && (this.v$.firstName.$invalid || this.v$.lastName.$invalid || this.v$.mobile.$invalid || this.v$.email.$invalid || this.v$.primaryAddress.addressLine1.$invalid || this.v$.primaryAddress.town.$invalid)) {
        return true
      } else {
        return false
      }
    },
    donatingType () {
      if (this.donateAs) {
        if (this.donateAs.groupID) {
          return 'group'
        } else if (this.donateAs.organisationID) {
          return 'organisation'
        } else {
          return 'user'
        }
      } else {
        return 'user'
      }
    },
    totalNumber () {
      if (this.cart.length > 0) {
        let ret = 0
        this.cart.forEach(item => {
          ret += Number(item.amount)
        })
        return parseFloat(Math.round(ret * 100) / 100).toFixed(2)
      } else {
        return 0
      }
    }
  },
  methods: {
    ...mapActions(['getTransactionReference', 'setDonateAs']),
    async verifyAnonDetails () {
      console.log('verifyAnonDetails')
      if (!this.user) {
        await this.v$.$touch()
        if (this.anonymousDetailsInvalid) {
          return false
        }
      }
    },
    trackCheckout (val) {
      this.$gtag.event('begin_checkout', {
        event_category: 'ecommerce',
        event_label: 'Card',
        value: val
      })
    },
    async getCurrentTransactionReference (paymentOption) {
      console.log('getCurrentTransactionReference dsdss')
      var paymentOptionVal = null
      if (paymentOption) {
        paymentOptionVal = paymentOption
      } else {
        paymentOptionVal = 1
      }
      var userID = null
      var organisationID = null
      var groupID = null
      var madeByFounder = null
      if (this.donatingType === 'organisation') {
        userID = this.user.userID
        organisationID = this.donatingID
      } else if (this.donatingType === 'group') {
        userID = this.user.userID
        organisationID = null
        groupID = this.donateAs.groupID
        madeByFounder = true
      } else {
        userID = this.donatingID
        organisationID = null
      }
      var checkoutDetails = {}
      if (!this.user) {
        console.log('this.primaryAddress', this.primaryAddress)
        checkoutDetails = {
          cart: this.cart,
          userID: userID,
          organisationID: organisationID,
          paymentOption: paymentOptionVal,
          ownMoney: this.ownMoney,
          giftAid: this.giftAid,
          sponsored: true,
          anonymousDetails: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            mobile: this.mobile,
            addressLine: this.primaryAddress.addressLine1,
            town: this.primaryAddress.town,
            postCode: this.primaryAddress.postalCode,
            country: this.primaryAddress.country.description ? this.primaryAddress.country.description : ''
          }
        }
        this.ukPaymentDetails = checkoutDetails.anonymousDetails
      } else if (this.donatingType === 'group') {
        checkoutDetails = {
          cart: this.cart,
          userID: userID,
          organisationID: null,
          paymentOption: paymentOptionVal,
          ownMoney: this.ownMoney,
          giftAid: this.giftAid,
          sponsored: true,
          groupID: groupID,
          madeByFounder: madeByFounder
        }
        const ukPaymentDetails = {
          firstName: this.donateAs.firstName,
          lastName: this.donateAs.lastName,
          email: this.donateAs.email,
          mobile: this.donateAs.cell,
          addressLine: this.donateAs.postalAddress1,
          town: this.donateAs.town,
          postCode: this.donateAs.postalcode,
          country: ''
        }
        if (this.user && this.user.country && this.user.country.isoCountryCode2) {
          ukPaymentDetails.country = this.user.country.isoCountryCode2
        }
        this.ukPaymentDetails = ukPaymentDetails
      } else {
        checkoutDetails = {
          cart: this.cart,
          userID: userID,
          organisationID: organisationID,
          ownMoney: this.ownMoney,
          giftAid: this.giftAid,
          sponsored: true,
          paymentOption: paymentOptionVal
        }
        const ukPaymentDetails = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          mobile: this.user.cell,
          addressLine: this.user.postalAddress1,
          town: this.user.town,
          postCode: this.user.postalcode,
          country: ''
        }
        if (this.user && this.user.country && this.user.country.isoCountryCode2) {
          ukPaymentDetails.country = this.user.country.isoCountryCode2
        }
        this.ukPaymentDetails = ukPaymentDetails
      }
      this.theCheckoutDetails = checkoutDetails
      if (paymentOptionVal !== 6) {
        const transaction = await this.getTransactionReference(checkoutDetails)
        this.transactionResponse = transaction.data
        if (paymentOptionVal === 1) {
          this.trackCheckout(this.transactionResponse.totalBankChargeAmount)
        }
      }
    },
    goToDonor () {
      var element = this.$refs.thedonor
      var top = element.offsetTop
      window.scrollTo(0, top)
    },
    saveEdit () {
      this.isEdit = false
    },
    updatePaymentType (val) {
      this.paymentType = val
    }
  }
}
</script>
