<template>
  <div class="container-lg normal-text-dark" >
    <div class="row m-0 align-items-center justify-content-center" v-if="adminProject">
      <div class="col-12 p-0">
        <PageTitle :title="contentProject.projectType.name" v-if="contentProject" />
        <PageTitle :title="adminProject.projectType" v-else />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                {{adminProject.description}}
              </div>
            </div>
            <div class="row mx-0 justify-content-center height">
              <div class="col-11 col-sm-9 col-md-8 col-lg-7 col-xl-6 col-xxl-6 h-100 p-0 relative box_shadow">
                <div class="imageSlider-item height">
                  <div class="h-100 text-center" ref="theImage">
                    <Image :imageName="contentProject.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="d-block w-100 slider_img" v-if="contentProject" />
                    <Image :imageName="adminProject.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="d-block w-100 slider_img" v-else-if="adminProject.imageUrl" />
                    <img src="../assets/images/default.png"  theClass="d-block w-100 slider_img img-fluid" v-else>
                    <div class="overlay">
                    </div>
                    <div class="price bold position-absolute start-50 translate-middle-x" v-if="mergedProject && mergedProject.fixed">
                      {{defaultCurrency}}{{mergedProject.cost}} {{mergedProject.costType.description}}
                    </div>
                    <div class="price bold position-absolute start-50 translate-middle-x" v-else-if="mergedProject && mergedProject.suggestedPrice">
                      {{defaultCurrency}}{{mergedProject.cost}} {{mergedProject.costType.description}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-6 my-2 border_end">
          <div class="row justify-content-center">
            <div class="col-auto text-center">
              <div class="page_title">
                DESCRIPTION
              </div>
            </div>
            <div class="col-12">
              <div class="page_title_underline mt-3 mx-auto"></div>
            </div>
          </div>
          <div class="row mx-0 my-4 my-md-5 justify-content-center">
            <div class="col-md-10 p-0" v-html="contentProject.description" v-if="contentProject">
            </div>
            <div class="col-md-10 p-0" v-html="adminProject.description" v-else>
            </div>
          </div>
        </div>
        <div class="col-lg-6 my-2">
          <div class="row justify-content-center">
            <div class="col-auto text-center">
              <div class="page_title">
                DONATE
              </div>
            </div>
            <div class="col-12">
              <div class="page_title_underline mt-3 mx-auto"></div>
            </div>
          </div>
          <div class="row mx-0 my-4 my-md-5 justify-content-center">
            <div class="col-md-10 p-0">
              <ProjectDonateChild :adminProject="adminProject" v-if="childProjects.length > 0" :cart="cart"/>
              <ProjectDonate :adminProject="adminProject" v-else :cart="cart"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('../components/PageTitle.vue')),
    Image: defineAsyncComponent(() => import('../components/Image.vue')),
    ProjectDonate: defineAsyncComponent(() => import('../views/ProjectDonate.vue')),
    ProjectDonateChild: defineAsyncComponent(() => import('../views/ProjectDonateChild.vue'))
  },
  name: 'Edit Individual Cart',
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  computed: {
    ...mapGetters(['adminProjects', 'contentProjects', 'suggestedPrice', 'cart']),
    mergedProject () {
      const id = parseInt(this.$route.params.id)
      const adminProject = this.adminProjects.find(project => project.projectID === id)
      const contentProject = this.contentProjects.find(project => project.adminProjectId === id)
      if (contentProject) {
        const mergedProject = { ...contentProject, ...adminProject }
        return mergedProject
      } else {
        return adminProject
      }
    },
    adminProject () {
      const id = parseInt(this.$route.params.id)
      return this.adminProjects.find(project => project.projectID === id)
    },
    contentProject () {
      const id = parseInt(this.$route.params.id)
      return this.contentProjects.find(project => project.adminProjectId === id)
    },
    childProjects () {
      const arr = this.adminProjects.filter(project => {
        if (project.parentProjectID !== this.mergedProject.projectID) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    }
  },
  methods: {
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    }
  }
}
</script>

<style scoped>
  .fill {
    max-height: 40vh;
  }
  .imageSlider-item {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .box_shadow {
    box-shadow: 0px 15px 24px 0px rgb(0 0 0 / 21%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 15px 15px 15px 15px;
    padding: 45px 45px 45px 45px;
    border-radius: 15px;
  }
  .height {
    height: 177px;
  }
  .slider_img  {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 22px;
  }
  .page_title {
    font-size: 14px;
    color: var(--green-color);
    font-family: "quicksand_bold", Sans-serif;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 6.8px;
  }
  .page_title.white {
    color: #fff;
  }
  .page_title_underline {
    border-bottom: 2px solid var(--gold-color);
    width: 131px;
  }
  .price {
    background-color: var(--green-color-dark);
    padding: 6px 15px;
    color: var(--gold-color);
    border-radius: 37px;
    width: auto;
    font-size: 12px;
    bottom: 25px;
    text-transform: lowercase;
  }
  .border_end {
    border-right: none;
  }
  @media (min-width: 992px) {
    .page_title {
      font-size: 21px;
    }
    .height {
      height: 325px;
    }
    .slider_img  {
      border-radius: 15px;
    }
    .page_title_underline {
      width: 200px;
    }
    .price {
      padding: 7px 17px;
      font-size: 23px;
      bottom: 45px;
    }
    .border_end {
      border-right: 1px solid rgb(204, 204, 204);
    }
  }
</style>
