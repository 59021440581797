<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                View and manage your recurring donations
              </div>
            </div>
            <div class="row m-0 justify-content-center">
              <div class="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 my-4">
                <Card icon="icon" heading="Setup Recurring Donation" @click="isRecurring = true" class="cursor">
                  <IconRecurring color="gold" size="size28" />
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5" ref="thedonations">
      <RecurringDonationsTable @goToDonations="goToDonations" />
    </div>
    <SetupRecurringDonationsUK v-if="isRecurring" @close="closeRecurring" @closeAll="closeAllRecurring" />
    <transition name="fade">
      <LoginPopup v-if="!user"/>
    </transition>
    <!-- <RecurringPaymentPopup v-if="isRecurring" @close="isRecurring = false" /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('../components/PageTitle.vue')),
    Card: defineAsyncComponent(() => import('../views/Card.vue')),
    RecurringDonationsTable: defineAsyncComponent(() => import('../views/RecurringDonationsTable.vue')),
    SetupRecurringDonationsUK: defineAsyncComponent(() => import('@/views/recurringDonations/uk/SetupRecurringDonations.vue')),
    IconRecurring: defineAsyncComponent(() => import('../components/icons/IconRecurring.vue'))
  },
  name: 'Recurring Donations',
  data () {
    return {
      isRecurring: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    ...mapActions(['fetchUserDebitOrders', 'clearCart']),
    goToDonations () {
      var element = this.$refs.thedonations
      var top = element.offsetTop
      window.scrollTo(0, top)
    },
    async closeRecurring () {
      this.isRecurring = false
    },
    async closeAllRecurring () {
      await this.fetchUserDebitOrders()
      await this.clearCart()
      this.isRecurring = false
    }
  }
}
</script>

<style scoped>
</style>
