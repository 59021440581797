<template>
  <div class="row m-0">
    <div class="coverBrowser fixed-top" v-if="isBrowser"></div>
    <div class="col-12">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center">
          <div class="col-11 text-center mt-4 page_heading d-none d-md-block">
            View your donation cart
          </div>
          <div class="col-12 col-sm-11 col-md-10 col-lg-10 col-xl-8 col-xxl-7 p-0 mt-4">
            <CartCard @updatePaymentType="updatePaymentType" v-model:paymentType="paymentType" :editStatus="isEdit" @saveEdit="saveEdit" @login="isLogin = true" />
            <div class="row mt-4">
              <div class="col-md-6 mb-3 relative" ref="thedonor" :class="{focusDonor: donateOnBehalf}" v-if="user">
                <!--DONOR-->
                <CardPlain v-if="donateAs && donatingType === 'organisation'">
                  <div class="row mx-0 justify-content-center text-center text-md-start">
                    <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3">
                      DONATING AS:
                    </div>
                    <div class="col-12 bold green-text-dark mb-2">
                      <div class="row mx-0 justify-content-center align-items-center" >
                        <div class="col-12 col-md-auto p-0 text-center">
                          <div class="profile_img m-auto">
                            <Image :imageName="donateAs.imageUrl" width="50" theClass="profile_img" v-if="donateAs.imageUrl" />
                            <!-- <img :src="url + '/static/uploads/images/' + donateAs.imageUrl" v-if="donateAs.imageUrl" class="profile_img"> -->
                            <IconUser size="sizefull" v-else />
                          </div>
                        </div>
                        <div class="col-12 col-md text-center text-md-start">
                          <div class="row mx-0" >
                            <div class="col-12 p-0 bold green-text font19">
                              {{donateAs.registeredName}}
                            </div>
                            <div class="col-12 p-0  medium green-text font10 letter_spacing">
                              ORGANISATION ID: {{donateAs.organisationID}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 bold green-text-dark" v-html="displayOrganisationAddress(donateAs)">
                    </div>
                    <div class="col-12 mt-3">
                      <SelectDonor
                        id="selectDonor"
                        placeholder="Change Donor"
                        @applyfilter="selectDonor"
                        title="Change Donor"
                        bg="bg_green"
                        color="white-text"
                        size="small"
                      >
                        <ios-shuffle-icon class="inline-icon small"/>
                      </SelectDonor>
                    </div>
                  </div>
                </CardPlain>
                <CardPlain v-else-if="donateAs && donatingType === 'group'">
                  <div class="row mx-0 justify-content-center text-center text-md-start">
                    <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3">
                      DONATING AS:
                    </div>
                    <div class="col-12 bold green-text-dark mb-2">
                      <div class="row mx-0 justify-content-center align-items-center" >
                        <div class="col-12 col-md-auto p-0 text-center">
                          <div class="profile_img m-auto">
                            <Image :imageName="donateAs.donor.imageUrl" width="50" theClass="profile_img" v-if="donateAs.donor.imageUrl" />
                            <!-- <img :src="url + '/static/uploads/images/' + donateAs.donor.imageUrl" v-if="donateAs.donor.imageUrl" class="profile_img"> -->
                            <IconUser size="sizefull" v-else />
                          </div>
                        </div>
                        <div class="col-12 col-md text-center text-md-start">
                          <div class="row mx-0" >
                            <div class="col-12 p-0 bold green-text font19">
                              {{donateAs.donor.firstName}} {{donateAs.donor.lastName}}
                            </div>
                            <div class="col-12 p-0  medium green-text font10 letter_spacing">
                              DONOR ID: {{donateAs.donor.donorID}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 bold green-text-dark" v-html="displayDonorAddress(donateAs.donor)">
                    </div>
                    <div class="col-12 mt-3">
                      <SelectDonor
                        id="selectDonor"
                        placeholder="Change Donor"
                        @applyfilter="selectDonor"
                        title="Change Donor"
                        bg="bg_green"
                        color="white-text"
                        size="small"
                      >
                        <ios-shuffle-icon class="inline-icon small"/>
                      </SelectDonor>
                    </div>
                  </div>
                </CardPlain>
                <CardPlain v-else-if="donateAs && donatingType === 'user'">
                  <div class="row mx-0 justify-content-center text-center text-md-start">
                    <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3">
                      DONATING AS:
                    </div>
                    <div class="col-12 bold green-text-dark mb-2">
                      <div class="row mx-0 justify-content-center align-items-center" >
                        <div class="col-12 col-md-auto p-0 text-center">
                          <div class="profile_img m-auto">
                            <Image :imageName="donateAs.imageUrl" width="50" theClass="profile_img" v-if="donateAs.imageUrl" />
                            <!-- <img :src="url + '/static/uploads/images/' + donateAs.imageUrl" v-if="donateAs.imageUrl" class="profile_img"> -->
                            <IconUser size="sizefull" v-else />
                            <!-- <img :src="donatingAs[donatingType].profile_picture" class="profile_img" v-if="donatingAs[donatingType].profile_picture" >
                            <IconUser size="sizefull" v-else /> -->
                          </div>
                        </div>
                        <div class="col-12 col-md text-center text-md-start">
                          <div class="row mx-0" >
                            <div class="col-12 p-0 bold green-text font19">
                              {{donateAs.firstName}}
                            </div>
                            <div class="col-12 p-0  medium green-text font10 letter_spacing">
                              DONOR ID: {{donateAs.donorID}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 bold green-text-dark" v-html="displayDonorAddress(donateAs)">
                    </div>
                    <div class="col-12 mt-3">
                      <SelectDonor
                        id="selectDonor"
                        placeholder="Change Donor"
                        @applyfilter="selectDonor"
                        title="Change Donor"
                        bg="bg_green"
                        color="white-text"
                        size="small"
                      >
                        <ios-shuffle-icon class="inline-icon small"/>
                      </SelectDonor>
                    </div>
                  </div>
                </CardPlain>
                <CardPlain v-else>
                  <div class="row mx-0 justify-content-center text-center text-md-start">
                    <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3">
                      DONATING AS:
                    </div>
                    <div class="col-12 bold green-text-dark mb-2">
                      <div class="row mx-0 justify-content-center align-items-center" >
                        <div class="col-12 col-md-auto p-0 text-center">
                          <div class="profile_img m-auto">
                            <Image :imageName="user.imageUrl" width="50" v-if="user.imageUrl" />
                            <!-- <img :src="url + '/static/uploads/images/' + user.imageUrl" v-if="user.imageUrl"> -->
                            <!-- <img :src="user.profile_picture" class="profile_img" v-if="user.profile_picture" > -->
                            <IconUser size="sizefull" v-else />
                          </div>
                        </div>
                        <div class="col-12 col-md text-center text-md-start">
                          <div class="row mx-0" >
                            <div class="col-12 p-0 bold green-text font19">
                              {{user.firstName}} {{user.lastName}}
                            </div>
                            <div class="col-12 p-0  medium green-text font10 letter_spacing">
                              DONOR ID: {{user.userID}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 bold green-text-dark" v-html="displayAddress(user.fullAddress)">
                    </div>
                    <div class="col-12 mt-3">
                      <SelectDonor
                        id="selectDonor"
                        placeholder="Change Donor"
                        @applyfilter="selectDonor"
                        title="Change Donor"
                        bg="bg_green"
                        color="white-text"
                        size="small"
                      >
                        <ios-shuffle-icon class="inline-icon small"/>
                      </SelectDonor>
                    </div>
                  </div>
                </CardPlain>
                <!--END DONOR-->
              </div>
              <div class="col-md-7 mb-3 relative" ref="thedonor" :class="{focusDonor: donateOnBehalf}" v-else-if="isUK">
                <!--ANONYMOUS-->
                <CardPlain >
                  <div class="row mx-0 justify-content-center ">
                    <div class="col-12 letter_spacing green-text-dark font13 bold mb-3 text-center ">
                      DONATING AS:
                    </div>
                    <div class="col-6 mb-3">
                      <TextField type="text" label="First Name:" required="required" placeholder="First Name" v-model="firstName" id="firstName" color="gold" :errors="v$.firstName.$errors" :hasErrors="v$.firstName.$errors.length" />
                    </div>
                    <div class="col-6 mb-3">
                      <TextField type="text" label="Last Name:" required="required" placeholder="Last Name" v-model="lastName" id="lastName" color="gold" :errors="v$.lastName.$errors" :hasErrors="v$.lastName.$errors.length" />
                    </div>
                    <div class="col-12 mb-3">
                      <TextField type="email" label="Email Address:" required="required" placeholder="email@mail.com" v-model="email" id="emailanon" color="gold" autocomplete="email" :errors="v$.email.$errors" :hasErrors="v$.email.$errors.length" />
                    </div>
                    <div class="col-12 mb-3">
                      <TextField type="text" label="Mobile/Contact No.:" required="required" placeholder="Mobile" v-model="mobile" id="mobileanon" color="gold" :errors="v$.mobile.$errors" :hasErrors="v$.mobile.$errors.length" />
                    </div>
                    <div class="col-12 mb-3">
                      <TextField type="text" label="Address Line 1:" placeholder="Address Line 1" required="required" v-model="primaryAddress.addressLine1" id="addressLine1" color="gold" :errors="v$.primaryAddress.addressLine1.$errors" :hasErrors="v$.primaryAddress.addressLine1.$errors.length" />
                    </div>
                    <div class="col-12 mb-3">
                      <TextField type="text" label="Town/City:" required="required" placeholder="Town/City" v-model="primaryAddress.town" id="town" color="gold" :errors="v$.primaryAddress.town.$errors" :hasErrors="v$.primaryAddress.town.$errors.length" />
                    </div>
                    <div class="col-6 mb-3">
                      <TextField type="text" label="Postal Code:" placeholder="Postal Code" v-model="primaryAddress.postalCode" id="postalCode" color="gold" />
                    </div>
                    <div class="col-6 mb-3">
                      <SelectAll
                        v-model="primaryAddress.country"
                        id="country"
                        :options="countries"
                        placeholder="Country"
                        displayValue="description"
                        color="gold"
                        label="Country"
                        :isSearch="true"
                      />
                    </div>
                    <div class="col-11 py-3 mt-3 border-top"></div>
                    <div class="col-12 col-md-11 bold green-text-dark mb-2">
                      <div class="row mx-0 justify-content-center align-items-center" >
                        <div class="col-12 col-md-auto p-0 text-center">
                          <div class="noprofile_img m-auto white-text">
                            <IconNoUser size="sizefull" />
                          </div>
                        </div>
                        <div class="col-12 col-md text-center text-md-start">
                          <div class="row mx-0" >
                            <div class="col-12 p-0 bold green-text font19">
                              Guest Donation
                            </div>
                            <div class="col-12 p-0  medium green-text-light font10">
                              This Donation will be made Anonymously
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-11 my-2">
                      <div class="row mx-0 justify-content-center align-items-center" >
                        <div class="col-12 text-center font13">
                          If you would like to make this donation under your donor profile click below:
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center">
                      <Button color="smallish" class="" size="small" btnText="Login/Register as a Donor" icon="arrow" @buttonClicked="isLogin = true">
                        <IconArrowForward color="white" />
                      </Button>
                    </div>
                  </div>
                </CardPlain>
                <!--END ANONYMOUS-->
              </div>
              <div class="col-md-6 mb-3" v-if="isUK && user">
                <!--GIFTAID-->
                <CardPlain>
                  <div class="row mx-0 justify-content-center text-center text-md-start">
                    <div class="col-auto col-md-12">
                      <div class="form-check">
                        <div class="row m-0 align-items-center">
                          <div class="col-auto p-0">
                            <input class="form-check-input" type="checkbox" value="true" id="giftaid" v-model="giftAid">
                          </div>
                          <div class="col-auto p-0">
                            <label class="form-check-label" for="flexCheckDefault">
                              <img src="../../assets/images/Gift-Aid-Logo-Green.png" class="img-fluid checkbox_img">
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 my-3 green-text-dark font17 ">
                      Yes, increase my donation of <b>{{total}}</b> to <b>{{giftaidTotal}}</b> at no extra cost
                    </div>
                    <div class="col-auto col-md-12 green-text-dark">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="true" id="money" v-model="ownMoney">
                        <label class="form-check-label" for="flexCheckDefault">
                          Yes, this is my own money
                        </label>
                      </div>
                    </div>
                    <div class="col-12 my-3 font9 medium">
                      I want to Gift Aid my donation and any donations I make in the future or have made in the past 4 years, to Al-Imdaad Foundation UK for the use of administration/operating costs. I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all of my donations in that tax year it is my responsibility to pay any difference.
                    </div>
                  </div>
                </CardPlain>
                <!--END GIFTAID-->
              </div>
              <div class="col-md-5 mb-3" v-if="isUK && !user">
                <!--GIFTAID-->
                <CardPlain>
                  <div class="row mx-0 justify-content-center text-center text-md-start">
                    <div class="col-auto col-md-12">
                      <div class="form-check">
                        <div class="row m-0 align-items-center">
                          <div class="col-auto p-0">
                            <input class="form-check-input" type="checkbox" value="true" id="giftaid" v-model="giftAid">
                          </div>
                          <div class="col-auto p-0">
                            <label class="form-check-label" for="flexCheckDefault">
                              <img src="../../assets/images/Gift-Aid-Logo-Green.png" class="img-fluid checkbox_img">
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 my-3 green-text-dark font17 ">
                      Yes, increase my donation of <b>{{total}}</b> to <b>{{giftaidTotal}}</b> at no extra cost
                    </div>
                    <div class="col-auto col-md-12 green-text-dark">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="true" id="money" v-model="ownMoney">
                        <label class="form-check-label" for="flexCheckDefault">
                          Yes, this is my own money
                        </label>
                      </div>
                    </div>
                    <div class="col-12 my-3 font9 medium">
                      I want to Gift Aid my donation and any donations I make in the future or have made in the past 4 years, to Al-Imdaad Foundation UK for the use of administration/operating costs. I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all of my donations in that tax year it is my responsibility to pay any difference.
                    </div>
                    <div class="col-11 py-3 mt-3 border-top"></div>
                    <div class="col-11 medium text-center">
                    Your tick for GiftAid allows us to maintain a 100% donations policy and ensures every penny of your donation benefits the needy recipients.
                    </div>
                  </div>
                </CardPlain>
                <!--END GIFTAID-->
              </div>
              <div class="col-12 mb-3">
                <!--PAYMENTS-->
                <CardPlain :disabled="!cart.length > 0">
                  <div class="row mx-0 justify-content-center text-center">
                    <!--PAYMENT OPTIONS-->
                    <div class="col-md-6 border_divider pt-3 pt-md-0 pe-md-4 order-md-first order-last relative">
                      <div class="onhover" v-if="!user" :class="{show: isHover === true }" @mouseover="isHover = true" @mouseleave="isHover = false">
                        <div class="row m-0 h-100 justify-content-center align-items-center">
                          <div class="col-auto">
                            <Button color="green" width="100" size="smallmobile" btnText="Login/Register as a Donor" icon="arrow" @buttonClicked="isLogin = true">
                              <IconArrowForward color="white" />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-0 justify-content-center text-center">
                        <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3">
                          PAYMENT OPTIONS
                        </div>
                        <div class="col-12 mb-3 font14">
                          Process your donation free of any charges or processing fees by choosing to pay via Bank Transfer<span v-if="isUK"> / Cheque</span>.
                        </div>
                        <div class="col-12 mb-2">
                          <Button color="green_light" width="100" btnText="Pay Via Bank Transfer" icon="arrow" size="small" @buttonClicked="payBank" class="mb-1" :isLoading="bankLoading">
                            <IconArrowForward size="size16" />
                          </Button>
                        </div>
                        <div class="col-12 mb-2" v-if="isUK">
                          <Button color="green_light" width="100" btnText="Pay Via Cheque" icon="arrow" size="small" @buttonClicked="payCheque" class="mb-1" :isLoading="chequeLoading">
                            <IconArrowForward size="size16" />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <!--END PAYMENT OPTIONS-->
                    <!--CHECKOUT-->
                    <div class="col-md-6 ps-md-4">
                      <div class="row mx-0 justify-content-center text-start">
                        <div class="col-md-12 letter_spacing green-text-dark font13 bold mb-3 text-center">
                          PAY BY CARD / ONLINE
                        </div>
                        <div class="col-9 mb-3 text-center">
                          <img src="../../assets/images/payments.png" class="img-fluid" v-if="isUK" />
                          <img src="../../assets/images/payment_logos.svg" class="img-fluid" v-else />
                        </div>
                        <div class="col-12 mb-3" v-if="paymentType === 'recurring'">
                          <Button color="red" width="100" size="smallmobile" btnText="Complete Donation" icon="arrow" @buttonClicked="isRecurring = true" :disabled="isEdit" id="paymentFormButton">
                            <IconArrowForward color="white" />
                          </Button>
                        </div>
                        <div class="col-12 mb-3" v-else>
                          <Button color="red" width="100" size="smallmobile" btnText="Complete Donation" icon="arrow" @buttonClicked="showAnonymousLoginPrompt" :disabled="isEdit" id="showAnonymousLoginPrompt" v-if="!isUK && !user" :isLoading="isLoading">
                            <IconArrowForward color="white" />
                          </Button>
                          <Button color="red" width="100" size="smallmobile" btnText="Complete Donation" icon="arrow" @buttonClicked="checkTurnstile" :disabled="isEdit" id="paymentFormButton" v-else>
                            <IconArrowForward color="white" />
                          </Button>
                        </div>
                        <!-- ADD BACK WHEN API HAS FUNCTIONALITY -->
                        <!-- <div class="col-auto col-md-12 green-text-dark mb-3 text-start">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="true" id="money" v-model="cardFee">
                            <label class="form-check-label" for="flexCheckDefault">
                              I would like to pay {{processingFee}} to cover the card processing fees for my donation
                            </label>
                          </div>
                        </div>
                        <div class="col-12 mb-3 font14">
                          Jazakallah Khair. This  helps us maintain our 100% Donations Policy.
                        </div> -->
                        <!-- ADD BACK WHEN API HAS FUNCTIONALITY -->
                      </div>
                    </div>
                    <!--END CHECKOUT-->
                  </div>
                </CardPlain>
                <!--END PAYMENTS-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 py-5 bg" v-if="isUK">
        <div class="col-12">
          <div class="container-lg" >
            <div class="row mx-0 align-items-md-center">
              <div class="col-md-6 py-5" ref="text_height">
                <div class="row m-0 justify-content-center justify-content-md-start">
                  <div class="col-12 gold_heading">
                    DID YOU KNOW?
                  </div>
                  <div class="col-12 green_heading">
                    You can increase<br/>your donation by<br/>25% for FREE
                  </div>
                  <div class="col-12 my-4 text">
                    If you're an all eligible tax-payer in the UK, you can increase all your donations by 25% at no extre cost to you, making each £1.00 you donate worth £1.25 and every £100 you donate into £125.00!
                  </div>
                  <div class="col-auto">
                    <Button color="green" btnText="Learn More" icon="arrow" @buttonClicked="goTo('giftaid')" class="mb-1" >
                      <IconArrowForward color="gold" />
                    </Button>
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-none d-md-block" :style="{height: theHeight}">
                <GiftAidAnimation :height="theHeight" />
              </div>
              <div class="col-12 order-first d-md-none">
                <GiftAidAnimation :width="theWidth" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <TurnstilePopup v-if="isTurnstile" @close="closeTurnstile" @verify="verifyTurnstile"/>
    </transition>
    <transition name="fade">
      <Popup v-if="isAnonymousLoginPrompt" @close="(isAnonymousLoginPrompt = false)">
        <div class="row mt-4 justify-content-center text-center">
          <div class="col-12 mb-3 text-center medium font23 green-text">
            You aren't logged in
          </div>
          <div class="col-10 mb-3 text-center font15">
            When donating anonymously you will not be able to obtain a tax certificate for your donation
          </div>
          <div class="col-10 mb-3">
            <Button color="red" width="100" size="smallmobile" btnText="Continue Anonymously" icon="arrow" @buttonClicked="continueAnonymously">
              <IconArrowForward color="white" />
            </Button>
          </div>
          <div class="col-10">
            <Button color="gold" width="100" size="smallmobile" btnText="Login/Register" icon="arrow" @buttonClicked="loginContinue" >
              <IconArrowForward color="white" />
            </Button>
          </div>
        </div>
      </Popup>
    </transition>
    <transition name="fade">
      <Popup v-if="tooSmall" @close="(tooSmall = false)">
        <div class="row mt-4 justify-content-center text-center">
          <div class="col-7 col-md-6 text-center">
            <ExclamationAnimation />
          </div>
          <div class="col-12 mb-3 text-center medium font23 green-text">
            Error
          </div>
          <div class="col-10 mb-3 bigger_font text-center medium green-text">
            The minimum donation total is <b>R5.00</b>
          </div>
          <div class="col-10 mb-3 text-center font15">
            Please increase your donation so the total is at least <b>R5.00</b>
          </div>
        </div>
      </Popup>
    </transition>
    <transition name="fade">
      <LoginPopup v-if="isLogin" @close="isLogin = false" @loggedIn="isLogin = false"/>
    </transition>
    <!-- <RecurringPaymentPopup v-if="isRecurring && isUK" @close="isRecurring = false" @closeAll="closeAll"/> -->
    <SetUpDebitOrder v-if="isRecurring" @close="isRecurring = false" @closeAll="closeAll" />
    <transition name="fade">
      <PayViaBank @close="isPayBank = false" @closeAll="closeAll" v-if="isPayBank" :total="total" :theCheckoutDetails="theCheckoutDetails" />
    </transition>
    <transition name="fade">
      <PayViaCheque @close="isPayCheque = false" @closeAll="closeAll" v-if="isPayCheque" :total="total" />
    </transition>
    <transition name="fade">
      <StripePaymentForm v-if="isUkPayment" @close="cancelPayment" :ukPaymentDetails="ukPaymentDetails" />
    </transition>
  </div>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
var turnstileVal = null

export default {
  components: {
    ExclamationAnimation: defineAsyncComponent(() => import('@/components/animations/ExclamationAnimation.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    TurnstilePopup: defineAsyncComponent(() => import('@/views/TurnstilePopup.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    CardPlain: defineAsyncComponent(() => import('@/components/CardPlain.vue')),
    CartCard: defineAsyncComponent(() => import('./views/cart/CartCard.vue')),
    PayViaBank: defineAsyncComponent(() => import('@/views/PayViaBank.vue')),
    PayViaCheque: defineAsyncComponent(() => import('@/views/PayViaCheque.vue')),
    GiftAidAnimation: defineAsyncComponent(() => import('@/components/animations/GiftAidAnimation.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    SetUpDebitOrder: defineAsyncComponent(() => import('@/views/SetUpDebitOrder.vue')),
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue')),
    IconUser: defineAsyncComponent(() => import('@/components/icons/IconUser.vue')),
    SelectDonor: defineAsyncComponent(() => import('@/components/SelectDonor.vue')),
    IconNoUser: defineAsyncComponent(() => import('@/components/icons/IconNoUser2.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    StripePaymentForm: defineAsyncComponent(() => import('@/views/stripe/StripePaymentForm.vue')),
    'ios-shuffle-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-shuffle.vue'))
  },
  name: 'Donation Checkout',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      mobile: {
        required
      },
      primaryAddress: {
        addressLine1: {
          required
        },
        town: {
          required
        }
      }
    }
  },
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      turnstileAppUrl: process.env.VUE_APP_TURNSTILE_APP_URL,
      isEdit: false,
      isHover: false,
      isTurnstile: false,
      giftAid: false,
      theHeight: '',
      theWidth: '',
      isRecurring: false,
      isLogin: false,
      cardFee: true,
      isPayment: false,
      ownMoney: false,
      donateOnBehalf: false,
      isPayBank: false,
      isPayCheque: false,
      paymentType: 'onceOff',
      donatingID: null,
      transactionResponse: null,
      creditCardDetails: null,
      isLoading: false,
      chequeLoading: false,
      bankLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      primaryAddress: {
        addressLine1: '',
        town: '',
        postalCode: '',
        country: {
          countryID: null,
          description: '',
          isoCountryCode2: ''
        }
      },
      tooSmall: false,
      turnstileReturn: null,
      theCheckoutDetails: null,
      isAnonymousLoginPrompt: false,
      isUkPayment: false,
      ukPaymentDetails: null,
      isBrowser: false
    }
  },
  watch: {
    giftAid () {
      this.setGiftAid(this.giftAid)
    }
  },
  mounted () {
    const edit = parseInt(this.$route.params.id)
    if (edit === 2) {
      this.isEdit = false
      this.goToDonor()
      this.donateOnBehalf = true
    } else if (edit === 1) {
      this.isEdit = true
    } else {
      this.isEdit = false
    }
    if (window.innerWidth < 992) {
      this.matchWidth()
    } else {
      this.matchHeight()
    }
    if (this.donateAs) {
      this.donatingID = this.donateAs.userID
    } else if (!this.donateAs && this.user) {
      this.donatingID = this.user.userID
    }
  },
  computed: {
    ...mapGetters([
      'cart', 'user', 'userDonors', 'donateAs', 'countries', 'isApp'
    ]),
    donatingType () {
      if (this.donateAs) {
        if (this.donateAs.groupID) {
          return 'group'
        } else if (this.donateAs.organisationID) {
          return 'organisation'
        } else {
          return 'user'
        }
      } else {
        return 'user'
      }
    },
    total () {
      if (this.cart.length > 0) {
        let ret = 0
        this.cart.forEach(item => {
          ret += Number(item.amount)
        })
        return this.cart[0].currency + '' + parseFloat(Math.round(ret * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return 0
      }
    },
    totalNumber () {
      if (this.cart.length > 0) {
        let ret = 0
        this.cart.forEach(item => {
          ret += Number(item.amount)
        })
        return parseFloat(Math.round(ret * 100) / 100).toFixed(2)
      } else {
        return 0
      }
    },
    processingFee () {
      if (this.cart.length > 0) {
        let ret = 0
        this.cart.forEach(item => {
          ret += Number(item.amount)
        })
        const theFee = ret * 0.015
        if (theFee > 5) {
          return this.cart[0].currency + '' + parseFloat(Math.round(5 * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        } else if (theFee < 0.1) {
          return this.cart[0].currency + '' + parseFloat(Math.round(0.1 * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        } else {
          return this.cart[0].currency + '' + parseFloat(Math.round(theFee * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        }
      } else {
        return 0
      }
    },
    giftaidTotal () {
      if (this.cart.length > 0) {
        let ret = 0
        this.cart.forEach(item => {
          ret += Number(item.amount)
        })
        return this.cart[0].currency + '' + parseFloat(Math.round(ret * 1.25 * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return 0
      }
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    donatingAs () {
      if (this.donatingID) {
        return this.userDonors.filter(donor => {
          if (donor.id !== this.donatingID) {
            return false
          }
          return true
        })
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions(['setGiftAid', 'checkout', 'getTransactionReference', 'clearCart', 'setDonateAs', 'cancelStripePayment', 'payApp']),
    closeTurnstile () {
      this.turnstileReturn = null
      this.isTurnstile = false
    },
    verifyTurnstile (val) {
      this.turnstileReturn = val
      this.isTurnstile = false
      if (this.turnstileReturn) {
        this.goCheckout()
      }
    },
    async verifyAppTurnstile () {
      if (this.turnstileReturn) {
        this.payAppNow()
      }
    },
    async isAppTurnstileCheck () {
      this.isBrowser = true
      var inAppBrowserRef
      console.log('this.turnstileAppUrl', this.turnstileAppUrl)
      if (window.device && window.device.platform && window.device.platform === 'Android') {
        inAppBrowserRef = window.cordova.InAppBrowser.open(`${this.turnstileAppUrl}`, '_blank', 'location=no,fullscreen=yes,footer=no,clearcache=yes')
      } else if (window.device && window.device.platform && window.device.platform === 'Windows') {
        inAppBrowserRef = window.cordova.InAppBrowser.open(`${this.turnstileAppUrl}`, '_blank', 'location=no,fullscreen=yes')
      } else {
        inAppBrowserRef = window.cordova.InAppBrowser.open(`${this.turnstileAppUrl}`, '_blank', 'location=no,enableViewportScale=yes,clearcache=yes,toolbar=no')
      }
      inAppBrowserRef.addEventListener('loadstop', loadStopCallBack)
      inAppBrowserRef.addEventListener('message', messageCallBack)
      inAppBrowserRef.addEventListener('exit', this.exitCallBack)

      function loadStopCallBack () {
        if (inAppBrowserRef !== undefined) {
          // eslint-disable-next-line no-multi-str
          inAppBrowserRef.executeScript({
            // eslint-disable-next-line no-multi-str
            code: "\
            document.getElementById('verifyBtn').onclick = function() {\
              const elements = document.getElementsByName('cf-turnstile-response');\
              const message = elements[0].value;\
              const messageObj = {my_message: message};\
              var stringifiedMessageObj = JSON.stringify(messageObj);\
              webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj);\
            }"
          })
          inAppBrowserRef.show()
        }
      }

      function messageCallBack (params) {
        console.log('MESSAGE RECEIVED', params)
        console.log('MESSAGE RECEIVED PARAMS', params.data.my_message)
        turnstileVal = params.data.my_message
        this.isBrowser = false
        inAppBrowserRef.close()
      }
    },
    exitCallBack () {
      console.log('EXIT', turnstileVal)
      this.turnstileReturn = turnstileVal
      this.isBrowser = false
      this.verifyAppTurnstile()
    },
    async payAppNow () {
      await this.getCurrentTransactionReference(1)
      const details = await this.payApp(this.turnstileReturn)
      this.creditCardDetails = details
      console.log('creditCardDetails - DETAILS', this.creditCardDetails, details)
      if (details) {
        const myData = {}
        // Merchant details
        myData.merchant_id = parseInt(details.merchant_id)
        myData.merchant_key = details.merchant_key
        myData.return_url = details.return_url
        myData.cancel_url = details.cancel_url
        myData.notify_url = details.notify_url
        // Transaction details
        myData.m_payment_id = details.m_payment_id
        myData.amount = parseFloat(details.amount_gross).toFixed(2)
        myData.item_name = details.item_name
        if (details.email_address) {
          myData.email_address = details.email_address
        }
        myData.email_confirmation = details.email_confirmation
        myData.payment_method = details.payment_method

        // Generate signature
        myData.signature = details.signature
        myData.redirect_url = details.redirect.url
        console.log('myData HERE', myData)
        await this.doPay(myData)
        //
      }
    },
    async doPay (myData) {
      this.isLoading = false
      this.isBrowser = true
      var inAppBrowserRef
      console.log('doPay = myData', myData)
      const theUrl = `https://s3.us-east-2.amazonaws.com/aiappstatic.co.za/payfast.html?merchant_id=${myData.merchant_id}&merchant_key=${myData.merchant_key}&return_url=${myData.return_url}&cancel_url=${myData.cancel_url}&notify_url=${myData.notify_url}&m_payment_id=${myData.m_payment_id}&amount=${myData.amount}&item_name=${myData.item_name}&email_address=${myData.email_address}&email_confirmation=${myData.email_confirmation}&payment_method=${myData.payment_method}&signature=${myData.signature}&redirect_url=${myData.redirect_url}`

      console.log('theUrl', theUrl)

      if (window.device && window.device.platform && window.device.platform === 'Android') {
        inAppBrowserRef = window.cordova.InAppBrowser.open(theUrl, '_blank', 'location=no,fullscreen=yes,footer=no,clearcache=yes')
      } else if (window.device && window.device.platform && window.device.platform === 'Windows') {
        inAppBrowserRef = window.cordova.InAppBrowser.open(theUrl, '_blank', 'location=no,fullscreen=yes')
      } else {
        inAppBrowserRef = window.cordova.InAppBrowser.open(theUrl, '_blank', 'location=no,enableViewportScale=yes,clearcache=yes,toolbar=no')
      }
      inAppBrowserRef.addEventListener('loadstop', (data) => {
        console.log('LOADSTOP HERE', data)
        console.log('data URL', data.url)
        if (data.url.includes('done') && !data.url.includes('payfast')) {
          this.isBrowser = false
          inAppBrowserRef.close()
          window.location.href = `${location.protocol}//${location.host}/#/paymentcomplete`
        }
        console.log(data)
      })
      inAppBrowserRef.addEventListener('exit', () => {
        console.log('EXIT HERE')
        window.location.href = `${location.protocol}//${location.host}/#/paymentcomplete`
      })
    },
    checkTurnstile () {
      if (this.isApp) {
        this.isLoading = true
        if (process.env.VUE_APP_COUNTRY !== 'UK' && this.totalNumber < 5) {
          this.tooSmall = true
        } else {
          this.isAppTurnstileCheck()
        }
      } else {
        if (process.env.VUE_APP_COUNTRY !== 'UK' && this.totalNumber < 5) {
          this.tooSmall = true
        } else if (!this.user) {
          this.v$.$touch()
          if (this.isUK && (this.v$.firstName.$invalid || this.v$.lastName.$invalid || this.v$.mobile.$invalid || this.v$.email.$invalid || this.v$.primaryAddress.addressLine1.$invalid || this.v$.primaryAddress.town.$invalid)) {
            return false
          } else {
            this.isTurnstile = true
          }
        } else {
          this.isTurnstile = true
        }
      }
    },
    showAnonymousLoginPrompt () {
      this.isAnonymousLoginPrompt = true
    },
    continueAnonymously () {
      this.isAnonymousLoginPrompt = false
      this.checkTurnstile()
    },
    loginContinue () {
      this.isAnonymousLoginPrompt = false
      this.isLogin = true
    },
    async goCheckout () {
      if (!this.user) {
        this.v$.$touch()
        if (this.isUK && (this.v$.firstName.$invalid || this.v$.lastName.$invalid || this.v$.mobile.$invalid || this.v$.email.$invalid || this.v$.primaryAddress.addressLine1.$invalid || this.v$.primaryAddress.town.$invalid)) {
          return false
        }
      }
      this.isLoading = true
      await this.getCurrentTransactionReference(1)
      const returnDetails = await this.checkout(this.turnstileReturn)
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        this.isLoading = false
        if (returnDetails) {
          this.isUkPayment = true
        }
        // var link = document.createElement('link')
        // link.setAttribute('rel', 'stylesheet')
        // link.setAttribute('type', 'text/css')
        // link.setAttribute('href', 'https://payments.worldpay.com/resources/hpp/integrations/embedded/css/hpp-embedded-integration-library.css')
        // document.head.appendChild(link)
        // const worldPay = document.createElement('script')
        // worldPay.setAttribute('src', 'https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js')
        // document.head.appendChild(worldPay)
      } else {
        if (returnDetails) {
          const myData = []
          // Merchant details
          myData.merchant_id = parseInt(returnDetails.merchant_id)
          myData.merchant_key = returnDetails.merchant_key
          myData.return_url = returnDetails.return_url
          myData.cancel_url = returnDetails.cancel_url
          myData.notify_url = returnDetails.notify_url
          // Transaction details
          myData.m_payment_id = returnDetails.m_payment_id
          myData.amount = parseFloat(returnDetails.amount_gross).toFixed(2)
          myData.item_name = returnDetails.item_name
          if (returnDetails.email_address) {
            myData.email_address = returnDetails.email_address
          }
          myData.email_confirmation = returnDetails.email_confirmation
          myData.payment_method = returnDetails.payment_method

          // Generate signature
          myData.signature = returnDetails.signature

          console.log('MYDAYA', myData)
          var form = document.createElement('form')
          form.setAttribute('method', 'post')
          form.setAttribute('action', returnDetails.redirect.url)
          for (const key in myData) {
            if (Object.prototype.hasOwnProperty.call(myData, key)) {
              const val = myData[key]
              if (val !== '') {
                var ele = document.createElement('input')
                ele.setAttribute('value', val)
                ele.setAttribute('name', key)
                // ele.value = val
                // ele.name = key
                form.appendChild(ele)
              }
            }
          }
          document.body.appendChild(form)
          form.submit()
          this.isLoading = false
        }
      }
      this.closeAll()
    },
    trackCheckout (val) {
      this.$gtag.event('begin_checkout', {
        event_category: 'ecommerce',
        event_label: 'Card',
        value: val
      })
    },
    trackCheckoutCheque (val) {
      this.$gtag.event('begin_checkout', {
        event_category: 'ecommerce',
        event_label: 'Cheque',
        value: val
      })
    },
    async closeAll () {
      await this.clearCart()
      this.isRecurring = false
      this.isPayBank = false
      this.isPayCheque = false
      this.turnstileReturn = null
      await this.setDonateAs(null)
    },
    async getCurrentTransactionReference (paymentOption) {
      var paymentOptionVal = null
      if (paymentOption) {
        paymentOptionVal = paymentOption
      } else {
        paymentOptionVal = 1
      }
      var userID = null
      var organisationID = null
      var groupID = null
      var madeByFounder = null
      if (this.donatingType === 'organisation') {
        userID = this.user.userID
        organisationID = this.donatingID
      } else if (this.donatingType === 'group') {
        userID = this.user.userID
        organisationID = null
        groupID = this.donateAs.groupID
        madeByFounder = true
      } else {
        userID = this.donatingID
        organisationID = null
      }
      var checkoutDetails = {}
      if (!this.user) {
        checkoutDetails = {
          cart: this.cart,
          userID: userID,
          organisationID: organisationID,
          paymentOption: paymentOptionVal,
          ownMoney: this.ownMoney,
          anonymousDetails: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            mobile: this.mobile,
            addressLine: this.primaryAddress.addressLine1,
            town: this.primaryAddress.town,
            postCode: this.primaryAddress.postalCode,
            country: this.primaryAddress.country.description
          }
        }
        this.ukPaymentDetails = checkoutDetails.anonymousDetails
      } else if (this.donatingType === 'group') {
        checkoutDetails = {
          cart: this.cart,
          userID: userID,
          organisationID: null,
          paymentOption: paymentOptionVal,
          ownMoney: this.ownMoney,
          groupID: groupID,
          madeByFounder: madeByFounder
        }
        const ukPaymentDetails = {
          firstName: this.donateAs.firstName,
          lastName: this.donateAs.lastName,
          email: this.donateAs.email,
          mobile: this.donateAs.cell,
          addressLine: this.donateAs.postalAddress1,
          town: this.donateAs.town,
          postCode: this.donateAs.postalcode,
          country: ''
        }
        if (this.user && this.user.country && this.user.country.isoCountryCode2) {
          ukPaymentDetails.country = this.user.country.isoCountryCode2
        }
        this.ukPaymentDetails = ukPaymentDetails
      } else {
        checkoutDetails = {
          cart: this.cart,
          userID: userID,
          organisationID: organisationID,
          ownMoney: this.ownMoney,
          paymentOption: paymentOptionVal
        }
        const ukPaymentDetails = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          mobile: this.user.cell,
          addressLine: this.user.postalAddress1,
          town: this.user.town,
          postCode: this.user.postalcode,
          country: ''
        }
        if (this.user && this.user.country && this.user.country.isoCountryCode2) {
          ukPaymentDetails.country = this.user.country.isoCountryCode2
        }
        this.ukPaymentDetails = ukPaymentDetails
      }
      this.theCheckoutDetails = checkoutDetails
      if (paymentOptionVal !== 6) {
        const transaction = await this.getTransactionReference(checkoutDetails)
        this.transactionResponse = transaction.data
        if (paymentOptionVal === 1) {
          this.trackCheckout(this.transactionResponse.totalBankChargeAmount)
        } else if (paymentOptionVal === 3) {
          this.trackCheckoutCheque(this.transactionResponse.totalBankChargeAmount)
        }
      }
      // const details = await creditCard(this.transactionResponse.transactionReference, PAYMENT_COMPLETE_URL)
      // this.creditCardDetails = details.data
      // this.hideLoading()
    },
    goToDonor () {
      var element = this.$refs.thedonor
      var top = element.offsetTop
      window.scrollTo(0, top)
    },
    selectDonor (val) {
      const type = val[0].slice(0, 3)
      this.donatingID = parseInt(val[0].slice(3))
      this.setDonateAs(val[1].userDetails)
      if (type === 'org') {
        this.donatingType = 'organisation'
      } else if (type === 'gro') {
        this.donatingType = 'group'
      } else {
        this.donatingType = 'user'
      }
    },
    async payBank () {
      this.bankLoading = true
      await this.getCurrentTransactionReference(6)
      if (this.theCheckoutDetails) {
        this.isPayBank = true
        this.bankLoading = false
      }
    },
    async payCheque () {
      this.chequeLoading = true
      await this.getCurrentTransactionReference(3)
      this.isPayCheque = true
      this.chequeLoading = false
    },
    saveEdit () {
      this.isEdit = false
    },
    updatePaymentType (val) {
      this.paymentType = val
    },
    matchHeight () {
      if (this.isUK) {
        var heightString = (this.$refs.text_height.clientHeight) + 'px'
        this.theHeight = heightString
      }
    },
    matchWidth () {
      if (this.isUK) {
        var widthString = (this.$refs.text_height.clientWidth) + 'px'
        this.theWidth = widthString
      }
    },
    displayAddress (val) {
      var str = val.replace(/,/g, '<br>')
      return str.replace(/<br><br>/g, '<br>')
    },
    displayDonorAddress (val) {
      return val.postalAddress1 + '<br>' + val.postalAddress2 + '<br>' + val.town + '<br>' + val.postalcode
    },
    displayOrganisationAddress (val) {
      const address = val.addressList[0]
      return address.addressLine1 + '<br>' + address.addressLine2 + '<br>' + address.town + '<br>' + address.postalCode
    },
    displayGroupAddress (val) {
      const address = val.addressList[0]
      return address.addressLine1 + '<br>' + address.addressLine2 + '<br>' + address.town + '<br>' + address.postalCode
    },
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    },
    cancelPayment () {
      this.isUkPayment = false
    }
  }
}
</script>

<style scoped>
.bg {
  background-color: rgb(244, 244, 244);
}
.border_divider {
  border-top: 1px solid rgb(239, 239, 239);
}
.checkbox_img {
  height: 39px;
}
.font9 {
  font-size: 9px;
}

.gold_heading {
  color: var( --gold-color );
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 6.4px;
  text-transform: uppercase;
  text-align: center;
}
.green_heading {
  color: var( --green-color );
  padding: 9px 0px 0px 0px;
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
}
.heading_title {
  color: #FFFFFF;
  font-family: "quicksand", Sans-serif;
  font-size: 3.5rem;
  font-weight: 100;
  line-height: 1.3em;
}
.heading_title_underline {
  border-bottom: 2px solid var(--gold-color);
}
.map_text {
  margin-top: -3rem;
  z-index: 2;
}
.text {
  font-size: 1rem;
  text-align: center;
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  /* box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%); */
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.noprofile_img {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  position: relative;
  border: 2px solid var(--green-color-dark);
  background-color: var(--green-color-dark);
  padding: 12px;
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.noprofile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.onhover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
}
.onhover.show {
  opacity: 1;
}
.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
  color: #000
}
.focusDonor {
  animation: fadeAndZoom;
  animation-duration: 2s;
  animation-timing-function: ease;
}
.coverBrowser {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--green-color-dark);
}
@keyframes fadeAndZoom {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  20% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media (min-width: 992px) {
  .gold_heading {
    color: var( --gold-color );
    font-family: "quicksand_bold", Sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 6.4px;
    text-transform: uppercase;
    text-align: left;
  }
  .green_heading {
    color: var( --green-color );
    padding: 9px 0px 0px 0px;
    font-family: "quicksand_bold", Sans-serif;
    font-size: 3.25rem;
    font-weight: bold;
    line-height: 1.4;
    text-align: left;
  }
  .map_text {
    margin-top: 0;
  }
  .text {
    font-size: 1.3rem;
    text-align: left;
  }
  .border_divider {
    border-top: none;
    border-right: 1px solid rgb(239, 239, 239);
  }
}
</style>
