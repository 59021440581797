<template>
  <TheLayout title="Donation Complete" :message="message" :status="status" :isLoading="isLoading" @downloadReceipt="downloadReceipt" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    TheLayout: defineAsyncComponent(() => import('../views/TheLayout.vue'))
  },
  name: 'Payment Complete',
  data () {
    return {
      message: '',
      status: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'transactions',
      'userTransactions',
      'organisationTransactions',
      'userGroupTransactions',
      'organisations',
      'groups',
      'groupsStatus',
      'organisationsStatus',
      'userTransactionsStatus',
      'organisationTransactionsStatus',
      'userGroupTransactionsStatus'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    ...mapActions(['getPayFastTransactionStatus', 'clearCart', 'getReceiptUrl', 'fetchGroups', 'fetchGroupTransactions', 'fetchOrganisations', 'fetchOrganisationTransactions', 'fetchUserTransactions']),
    async downloadReceipt () {
      this.isLoading = true
      await this.getReceiptUrl()
      setTimeout(() => {
        this.isLoading = false
      }, 3000)
    },
    async getTransactions () {
      await this.fetchGroups()
      if (this.groups && this.groupsTransactions && this.groupsTransactionsStatus !== 'loading') {
        await this.groups.forEach(async group => {
          await this.fetchGroupTransactions(group.donorGroupID)
        })
      }
      await this.fetchOrganisations()
      if (this.organisations && this.organisationTransactions && this.organisationTransactionsStatus !== 'loading') {
        await this.organisations.forEach(async organisation => {
          await this.fetchOrganisationTransactions(organisation.organisationID)
        })
      }
      await this.fetchUserTransactions()
    }
  },
  async mounted () {
    try {
      const result = await this.getPayFastTransactionStatus()
      console.log('RESULT', result)
      if (result) {
        this.status = result.data.result.status
        if (result.data.result.status === 'Success') {
          this.message = 'Your donation has been completed successfully'
          this.downloadReceipt()
          this.clearCart()
          if (this.user) {
            this.getTransactions()
          }
        } else {
          this.message = result.data.result.description ? result.data.result.description : 'There was an issue with your payment, please try again later or contact support'
        }
      } else {
        this.message = 'There was an issue with your payment, please try again later or contact support'
      }
    } catch (e) {
      this.message = 'There was an issue with your payment, please try again later or contact support'
    }
  }
}
</script>
