<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_tp">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                Make a difference with<br/>your donations today.
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-11 col-md-11 col-lg-10 col-xl-9 p-0">
            <div class="row m-0 h-100">
              <div class="col-lg-4 mb-3">
                <QuickDonate />
              </div>
              <div class="col-lg-8 p-0">
                <QuickDonateLinks />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider />
      <div class="container-lg">
        <AppealSection title="Browse all our appeals" :showDesktop="true" btnText="Donate" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue')),
    QuickDonate: defineAsyncComponent(() => import('./views/QuickDonate.vue')),
    QuickDonateLinks: defineAsyncComponent(() => import('./views/QuickDonateLinks.vue')),
    AppealSection: defineAsyncComponent(() => import('../appeals/views/AppealSection.vue'))
  },
  name: 'Donate'
}
</script>
